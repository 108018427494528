
import { Component, Vue } from 'vue-property-decorator'
import { Info } from '@/types/plant'
import { FileInfo } from '@/types/common'

@Component({
  filters: {
    imgListFilter (arr: Array<FileInfo>) {
      return arr.map(item => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class PlantDetail extends Vue {
  private plantTab = ['识别特征', '观赏特性', '养护要点', '生物学特征', '生态学特征', '品种介绍', '园林应用', '花语', '植物文化']
  private tabIndex = '0'
  private info: Info | {} = {}
  get plantId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.plantId) {
      this.getDetail()
    }
  }

  getDetail () {
    this.$axios.get(this.$apis.plant.plantDetail, {
      plantId: this.$route.params.id
    }).then((res) => {
      this.info = res
    })
  }
}
